<template>
  <v-dialog data-app v-model="opened" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="26">mdi-close</v-icon>
      <v-card-title>{{data.name || houseMap &&
      houseMap.find(room=>room.n_id === data.type) ? houseMap.find(room=>room.n_id === data.type).name : ''}}</v-card-title>
      <v-card-text>
        <v-form ref="form" class="pt-4">
          <v-row class="row-d-block mb-4">
            <v-subheader>{{ filePhoto && filePhoto.name || 'Прикрепите фотографию' }}</v-subheader>
            <div class="img-loader" v-if="uploadPhotoLoading">
              <v-progress-circular indeterminate/>
            </div>
            <div class="img-container mt-4" v-else-if="data.photo">
              <v-icon class="remove-image-btn" @click="data.photo = ''; filePhoto = null" size="16"
                      color="#333">mdi-close
              </v-icon>
              <img :src="data.photo" alt="">
            </div>
            <v-file-input v-else v-model="filePhoto" dense outlined @change="onImageSelect" required
                          accept="image/*"
                          :rules="[v=>!!v || 'Прикрепите фотографию']"/>
          </v-row>
          <v-textarea v-model="data.text" dense outlined
                      :placeholder="houseMap &&
                      houseMap.find(room=>room.n_id === data.type) ? houseMap.find(room=>room.n_id === data.type).placeholder : 'Добавьте комментарий'"/>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="update" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EditHouseMap",
  emits: ['updated'],
  data: () => ({
    opened: false,
    filePhoto: null,
    houseMap: [],
    data: {
      id: '',
      item_id: '',//address.id
      type: '',
      text: '',
      photo: ''
    },
    loading: false,
    uploadPhotoLoading: false,
  }),
  methods: {
    open(params) {
      this.houseMap = [];
      this.data = {
        id: '',
        item_id: '',
        type: '',
        text: '',
        photo: ''
      };
      this.filePhoto = null;
      this.opened = true;
      if (params.card) this.data = {...params.card}
      this.houseMap = params.houseMap;
      this.data.item_id = params.address_id;
    },
    onImageSelect(file) {
      const formData = new FormData()
      formData.append('file', file, file.name);
      this.uploadPhotoLoading = true;
      this.axios.post('https://cleanbros.ru/upload', formData)
          .then(data => {
            this.data.photo = data.data.response;
            this.uploadPhotoLoading = false;
          })
          .catch(e => {
            this.uploadPhotoLoading = false;
            this.$root.notify(e, 'error')
          })
    },
    update() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        console.log({...this.data})
        this.$store.state.server.request(`housemap/${this.data.n_id ? `update/${this.data.n_id}` : 'create'}`, this.data, () => {
          this.loading = false;
          this.$emit('updated');
          this.opened = false;
        }, () =>{
          this.loading = false;
        })
      }
    }
  },
  computed: {
    rooms() {
      return this.houseMap && this.houseMap.length ? this.houseMap.map(i => ({
        id: i.n_id,
        name: i.name
      })) : [];
    }
  }
}
</script>

<style scoped>
.btn-close-modal {
  top: 16px;
  right: 16px;
}

img {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  object-fit: cover;
}

.img-loader {
  width: 96px;
  height: 96px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eeeeee;
}
.img-container {
  position: relative;
  width: fit-content;
}

.remove-image-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
}
</style>