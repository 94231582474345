<template>
  <v-dialog max-width="800px" scrollable data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-icon @click="download" size="36">mdi-download</v-icon>
      <v-card-text v-html="act"></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "Act",
  data() {
    return {
      act: '',
      pdfURL: '',
      id: null,
      opened: false,
    }
  },
  methods: {
    download() {
      window.location.href = this.pdfURL
    },
    open(id) {
      this.id = id;
      this.opened = true;
      this.$store.state.server.request(`order/getAct/${id}`, {}, (data) => {
        this.act = data.response
        this.pdfURL = data.pdfURL
      })
    },
  }
}
</script>
