<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Оценка</v-card-title>
      <v-card-text style="padding-bottom: 0">
        <v-row class="mb-1 row-d-block">
          Оформил менеджер <span style="color: #3399ff"
                                 v-if="+order.user_id">{{ $root.managerName(order.user_id) }}</span>
        </v-row>
        <v-form ref="form">
          <v-row class="mb-1 justify-space-between">
            <ClientLink :value="order.client" class="my-0"/>
            <!--/*            <v-btn @click="openOrder" style="background: #eee !important">*/-->
            <router-link :to="`/orders/${order.n_id}`" target="_blank"
                         class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default"
                         style="background: #eee !important"
            >Открыть заказ
            </router-link>
            <!--          </v-btn>-->
          </v-row>
          <v-row class="mb-1 row-d-block">
            <v-subheader>Клиент оценил клинера (видит клиент)</v-subheader>
            <v-rating
                color="amber"
                background-color="amber"
                @input="v=>{checkError(); onChangeClientRate(v)}"
                v-model="order.feedback.rate"
                hover
                :readonly="Boolean(+order.feedback.by_client)"
                size="32"
                class="rating"
            />
          </v-row>
          <v-row v-if="$store.state.user.feedbackCheckboxes" class="mb-1 row-d-block">
            <v-expansion-panels v-model="expansionPanelCleaner" multiple>
              <v-expansion-panel class="expansion-panel-no-shadow">
                <v-expansion-panel-header class="pa-0">
                  <v-subheader>Недочеты клинера</v-subheader>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-checkbox
                      v-for="checkbox in $store.state.user.feedbackCheckboxes.filter(_checkbox=>_checkbox.n_id<8)"
                      v-model="checkboxes"
                      :key="checkbox.n_id"
                      :label="checkbox.title"
                      :value="checkbox.n_id"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row v-if="!order.feedback.rate || +order.feedback.rate < 5" class="mb-1 row-d-block">
            <v-subheader>Исправленная оценка клинера
              <InfoTip
                  value="Можно поставить, если оценка от клиента неадекватная, в статистике будет учитываться она"/>
            </v-subheader>
            <v-rating
                color="amber"
                background-color="amber"
                @input="checkError"
                v-model="order.feedback.rate_changed"
                hover
                size="32"
                class="rating"
            />
          </v-row>
          <v-row class="mb-1 row-d-block">
            <v-subheader>Клиент оценил менеджера</v-subheader>
            <v-rating
                color="amber"
                background-color="amber"
                @input="v=>{checkError(); onChangeClientRateManager(v)}"
                v-model="order.feedback.rate_manager"
                :readonly="Boolean(+order.feedback.by_client)"
                hover
                size="32"
                class="rating"
            />
          </v-row>
          <v-row class="mb-1 row-d-block" v-if="$store.state.user.feedbackCheckboxes">
            <v-expansion-panels v-model="expansionPanelManager" multiple>
              <v-expansion-panel class="expansion-panel-no-shadow">
                <v-expansion-panel-header class="pa-0">
                  <v-subheader>Недочеты менеджера</v-subheader>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0">
                  <v-checkbox
                      v-for="checkbox in $store.state.user.feedbackCheckboxes.filter(_checkbox=>_checkbox.n_id>=8)"
                      v-model="checkboxes"
                      :key="checkbox.n_id"
                      :label="checkbox.title"
                      :value="checkbox.n_id"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-row v-if="!order.feedback.rate_manager || +order.feedback.rate_manager < 5" class="mb-1 row-d-block">
            <v-subheader>Исправленная оценка менеджера
              <InfoTip
                  value="Можно поставить, если оценка от клиента неадекватная, в статистике будет учитываться она"/>
            </v-subheader>
            <v-rating
                color="amber"
                background-color="amber"
                @input="checkError"
                v-model="order.feedback.rate_manager_changed"
                hover
                size="32"
                class="rating"
            />
          </v-row>
          <v-row v-if="+order.feedback.by_client || order.feedback.comment" class="mb-1 row-d-block">
            <v-subheader>Комментарий клиента</v-subheader>
            <v-textarea outlined rows="2" readonly
                        v-model="order.feedback.comment">
            </v-textarea>
          </v-row>
          <v-row class="mb-1 row-d-block">
            <v-subheader>Комментарий менеджера</v-subheader>
            <v-textarea outlined rows="2"
                        v-model="order.feedback.comment_manager">
            </v-textarea>
          </v-row>
          <v-row class="mb-1 row-d-block">
            <v-subheader>Статус</v-subheader>
            <v-select
                v-model="order.feedback.status"
                :items="statuses"
                @change="checkError"
                item-text="text"
                item-value="value"
                outlined/>
          </v-row>
          <v-row class="mb-1 row-d-block">
            <CleanerLink style="display: inline-block" class="mr-2" v-for="cleaner in order.cleaners"
                         :value="cleaner"></CleanerLink>
          </v-row>
          <v-card class="mt-6 my-2">
            <v-card-text class="py-2">
              <v-row class="mb-1 row-d-block">
                <v-checkbox label="Есть конфликт" v-model="order.feedback.conflict" :true-value="1" :false-value="0"
                            @change="onConflictChange"/>
              </v-row>
              <v-expansion-panels class="mb-4" v-model="expansionPanelConflict" multiple>
                <v-expansion-panel class="expansion-panel-no-shadow">
                  <v-expansion-panel-content class="pa-0">
                    <v-row class="mb-1 row-d-block">
                      <v-subheader>Способ урегулирования</v-subheader>
                      <v-select v-model="order.feedback.resolve_type"
                                :items="$root.selectValues($store.state.user.feedbackResolveOptions)" outlined
                                item-value="value" item-text="name" @change="onConflictTypeChange"/>
                    </v-row>
                    <v-row v-if="['bonus', 'money_back', 'compensation'].includes(order.feedback.resolve_type)"
                           class="mb-1 row-d-block">
                      <v-subheader>Сумма</v-subheader>
                      <v-text-field v-model="order.feedback.conflict_sum" hide-details outlined
                                    @change="onConflictSumChange"/>
                      <v-checkbox v-if="order.feedback.resolve_type === 'money_back'" label="Полная сумма заказа"
                                  @change="onChangeFullReturn"/>
                    </v-row>
                    <v-row class="mb-1 row-d-block">
                      <v-checkbox label="Конфликт урегулирован" v-model="order.feedback.conflict_resolve"
                                  :true-value="1"
                                  :false-value="0"/>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
          <v-row v-if="order.feedback.comment" class="mb-1 row-d-block">
            <v-checkbox v-model="order.feedback.sendToCleaners" :true-value="1" :false-value="0">
              <template #label>
                <div class="d-flex align-items-start">
                  <InfoTip value="Отзыв отправится клинерам с этого заказа"/>
                  <span class="pl-2">Отправить клинерам (для хороших отзывов)</span>
                </div>
              </template>
            </v-checkbox>
          </v-row>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader>{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="createNewOrder" style="background: #eee !important">Оформить новый заказ</v-btn>
      </v-card-actions>
      <v-card-actions style="background: #ffffff">
        <v-btn @click="save" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CleanerLink from "../../../components/CleanerLink";
import ClientLink from "../../../components/ClientLink";
import InfoTip from "../../../components/InfoTip";

export default {
  name: "Review",
  components: {InfoTip, ClientLink, CleanerLink},
  data() {
    return {
      opened: false,
      error: null,
      loading: false,
      order: {feedback: {return: '', conflict_resolve: 0}},
      checkboxes: [],
      statuses: [
        {text: '', value: '0'},
        {text: 'Недозвон', value: '1'},
        {text: 'Архив', value: '2'},
      ],
      expansionPanelCleaner: [],
      expansionPanelManager: [],
      expansionPanelConflict: []
    }
  },
  methods: {
    open(order) {
      this.order = {feedback: {return: '', conflict_resolve: 0, resolve_type: ''}};
      this.opened = true;
      this.order = order;
      this.checkboxes = this.order.feedback.checkboxes || [];
      this.error = '';
      this.expansionPanelCleaner = this.order.feedback.rate && +this.order.feedback.rate < 5 ? [0] : [];
      this.expansionPanelManager = this.order.feedback.rate_manager && +this.order.feedback.rate_manager < 5 ? [0] : [];
      if (+this.order.feedback.datetime_conflict_start) {
        this.order.feedback.conflict = 1;
        this.expansionPanelConflict = [0];
      }
      if (+this.order.feedback.datetime_conflict_end) this.order.feedback.conflict_resolve = 1;
    },
    onToggle() {
    },
    checkError() {
      if (!parseInt(this.order.feedback.status) && !parseInt(this.order.feedback.rate)) {
        this.error = 'Укажите оценку или поменяйте статус';
      } else if (+this.order.feedback.datetime_conflict_end && !+this.order.feedback.conflict_resolve) {
        this.error = 'Укажите способ урегулирования конфликта';
      } else {
        this.error = '';
      }
      return !this.error;
    },
    save() {
      if (!this.checkError()) return;
      this.loading = true;
      this.order.feedback.checkboxes = this.checkboxes || [];
      this.$store.state.server.request(`order/rate/${this.order.n_id}`, this.order.feedback, () => {
        this.loading = false;
        this.$root.notify('Отзыв сохранен', 'success')
        this.$eventBus.$emit('feedbackUpdated')
        this.opened = false;
      }, (data) => {
        this.opened = false;
        if (data && data.error) this.$root.notify(data.error, 'error')
      })
    },
    onChangeFullReturn(val) {
      this.order.feedback.conflict_sum = val ? this.order.totalPay : '';
      this.$forceUpdate();
    },
    createNewOrder() {
      const routeData = this.$router.resolve({
        path: `/create/${this.order.client.phone}`,
        query: {source_from: 'feedback'}
      });
      window.open(routeData.href, '_blank');
    },
    onConflictChange(val) {
      this.expansionPanelConflict = val ? [0] : [];
    },
    onChangeClientRate(val) {
      this.expansionPanelCleaner = +val < 5 ? [0] : [];
    },
    onChangeClientRateManager(val) {
      this.expansionPanelManager = +val < 5 ? [0] : [];
    },
    onConflictSumChange(val) {
      if (this.order.feedback.resolve_type === 'bonus') {
        this.order.feedback.bonus = val;
        this.order.feedback.conflict_resolve = 1;
        this.$forceUpdate();
      }
    },
    onConflictTypeChange(val) {
      if (val !== 'bonus') {
        this.order.feedback.bonus = '';
        if (val === 'reorder') {
          this.order.feedback.conflict_sum = '';
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
