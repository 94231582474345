<template>
  <v-dialog data-app v-model="opened" max-width="600px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="26">mdi-close</v-icon>
      <v-card-title>Карта дома</v-card-title>
      <v-card-text v-if="loading" class="py-8 d-flex justify-content-center">
        <v-progress-circular indeterminate/>
      </v-card-text>
      <v-card-text v-else>
        <div v-for="(card, index) in houseMap" :key="index" class="room-container">
          <v-card-subtitle class="room-title px-0 py-2 d-flex align-items-center">{{ card.name }}
            <v-btn class="ml-auto" rounded small @click="addPhotoToHouseMap({type: card.n_id, name: card.name})">
              <v-icon size="14" class="mr-1">mdi-plus</v-icon>
              Добавить
            </v-btn>
          </v-card-subtitle>
          <viewer :images="card.cards" class="d-flex flex-wrap image-viewer" v-if="card.cards && card.cards.length">
            <div class="image-container" v-for="(photo, index) in card.cards" :key="index">
              <v-icon class="remove-image-btn" @click="openRemoveImagePrompt(photo.n_id)" size="16">mdi-close</v-icon>
              <v-icon class="edit-image-btn" size="16" @click="addPhotoToHouseMap(photo)">mdi-pencil</v-icon>
              <figure>
                <img :src="photo.photo" :alt="photo.text" class="viewer-image__item">
                <figcaption class="image-caption">{{photo.text}}</figcaption>
              </figure>
            </div>
          </viewer>
          <div v-else class="empty-room-cards pt-2 pb-6">Фотографий нет</div>
        </div>
      </v-card-text>
    </v-card>
    <EditHouseMap ref="edit_house_map_dialog" @updated="$emit('updated'); getHouseMap()"/>
    <Prompt ref="remove_card_prompt_dialog"
            :loading="removeLoading"
            title="Вы уверены, что хотите удалить карточку?" @success="removeImage"/>
  </v-dialog>
</template>

<script>
import EditHouseMap from "@/views/orders/modals/EditHouseMap";
import Prompt from "@/containers/Modals/Prompt";

export default {
  name: "HouseMap",
  components: {Prompt, EditHouseMap},
  emits: ['updated'],
  data() {
    return {
      opened: false,
      houseMap: [],
      address_id: '',
      loading: false,
      removeLoading: false,
    }
  },
  methods: {
    open() {
      this.houseMap = [];
      this.opened = true;
      this.getHouseMap(true);
    },
    addPhotoToHouseMap(card) {
      this.$refs.edit_house_map_dialog.open({houseMap: this.houseMap, address_id: this.address_id, card});
    },
    openRemoveImagePrompt(id) {
      this.$refs.remove_card_prompt_dialog.open(id);
    },
    removeImage(id) {
      this.removeLoading = true;
      this.$store.state.server.request(`housemap/delete/${id}`, {}, () => {
        this.$emit('updated');
        this.removeLoading = false;
        this.$refs.remove_card_prompt_dialog.close();
        this.getHouseMap(false);
      }, ()=>{
        this.removeLoading = false;
      })
    },
    getHouseMap(firstLoad = false) {
      if (firstLoad) this.loading = true;
      this.$store.state.server.request(`housemap/get/${this.address_id}`, {}, (data) => {
        this.houseMap = data.response;
        this.loading = false;
      }, () => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>
.btn-close-modal {
  top: 16px;
  right: 16px;
}

.room-container:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 16px;
}

.room-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.image-container {
  position: relative;
  width: fit-content;
  max-width: 160px;
}

.image-viewer {
    gap: 8px;
}

.viewer-image__item {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 8px;
}

/*
.image-caption {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
*/

.edit-image-btn {
  position: absolute;
  top: 8px;
  left: 8px;
}

.remove-image-btn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.empty-room-cards {
  text-align: center;
  font-size: 16px;
}
</style>