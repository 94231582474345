<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Проблема</v-card-title>
      <v-card-text>
        <v-form
                ref="form">
          <v-row>
            <v-textarea
                v-model="comment"
                outlined
                rows="4"
                placeholder="Комментарий"
                />
          </v-row>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader>{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
name: "Problem",
  data() {
    return {
      loading: false,
      order_id: null,
      opened: false,
      error: null,
      comment: null,
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    onToggle() {
      this.formData = {}
    },
    save() {
        this.loading = true;
        this.$store.state.server.request(`order/problem/${this.order_id}`, {comment: this.comment}, (data)=>{
          this.$eventBus.$emit('orderProblemSaved', true)
          this.loading = false;
          this.close()
        }, (data)=>{
          this.error = data.error
          this.loading = false;
        })
    },
  }
}
</script>

<style scoped>

</style>