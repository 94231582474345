<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Отправить в другой город</v-card-title>
      <v-card-text>
        <v-form
                ref="form">
          <v-row class="mb-4">
            <v-select
                outlined
                v-model="city"
                :items="$store.state.user.allCities"
                item-value="id"
                item-text="city"/>
          </v-row>
          <v-row>
            <v-textarea
                v-model="comment"
                outlined
                rows="4"
                placeholder="Комментарий"
                />
          </v-row>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader>{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ToOtherCity",
  data() {
    return {
      opened: false,
      order_id: null,
      city: null,
      comment: '',
      error: null,
      loading: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.comment = `Передача из города ${this.$store.state.user.city ? this.$store.state.user.city.city : ''}`;
    },
    onToggle() {
      this.city = null;
      this.comment = null;
    },
    save() {
      this.loading = true;
      this.$store.state.server.request(`order/sendTo/${this.order_id}`, {city_id: this.city, comment: this.comment}, (data)=>{
        this.$root.notify(`Заказ передан из ${this.$store.state.user.city ? this.$store.state.user.city.city : ''} в ${this.$store.state.user.cities.find(el=>el.id === this.city).city }`)
        this.loading = false;
        this.$eventBus.$emit('otherCity', data.response)
        this.opened = false;
      }, (data)=>{
        this.loading = false;
        this.error = data.error
      })
    }
  }
}
</script>

<style scoped>

</style>