<template>
  <v-dialog data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>История заказа</v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="history"
          :loading="loading"
          item-key="n_id"
          :footer-props="{'items-per-page-options':[-1]}"
          no-data-text="Истории нет"
          loading-text="Загрузка..."
          >
          <template v-slot:item="{item}">
            <tr>
              <td>{{ $root.dateToRus(item.datetimeObj.date) }} {{item.datetimeObj.time}}</td>
              <td>
                <template v-if="item.user_type === 'manager' && item.user">
                  Менеджер {{ item.user.fname }} ({{item.user.login}})
                </template>
                <template v-else-if="item.user_type === 'worker' && item.user">
                  Клинер {{ item.user.name }}
                </template>
                <template v-else-if="item.user_type === 'client' && item.user">
                  Клиент {{ item.user.name }}
                </template>
              </td>
              <td style="max-width: 300px;white-space: normal;">{{item.type}}</td>
              <td>
                <v-simple-table dense v-if="item.params && Object.keys(item.params).length">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">
                        Параметр
                      </th>
                      <th class="text-left">
                        Было
                      </th>
                      <th class="text-left">
                        Стало
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(changes, key) in item.params"
                        :key="key"
                        v-if="key"
                    >
                      <td>{{ key }}</td>
                      <td>{{ changes[0] }}</td>
                      <td>{{ changes[1] }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
name: "history",
  data() {
    return {
      loading: true,
      order_id: null,
      opened: false,
      headers: [
        {text: 'Дата', value: 'datetimeObj'},
        {text: 'Кто изменил', value: 'user'},
        {text: 'Тип', value: 'type'},
        {text: 'Изменения', value: 'params'},
      ],
      history: [],
    }
  },
  methods: {
    open() {
      this.history = [];
      this.opened = true;
      this.getHistory()
    },
    getHistory() {
      this.$store.state.server.request(`order/history/${this.order_id}`, {}, (data)=>{
        this.history = data.response;
        this.loading = false;
        console.log('history', this.history)
      }, ()=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>