<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Не заказал</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row class="mb-4">
            <v-btn class="mr-2" :class="!type?'' : 'v-btn-transp'" @click="type=0;reason=''">Целевой
            </v-btn>
            <v-btn :class="type?'' : 'v-btn-transp'" @click="type=1;reason=''">Не целевой</v-btn>
          </v-row>
          <v-row class="mb-4" v-if="$store.state.user.leadSections">
            <v-select
                v-model="reason"
                :items="$store.state.user.leadSections[type?2:0].subsections.filter(s=>s!=='Все')"
                placeholder="Выберите тип"
                outlined
            />
          </v-row>
          <v-row class="mb-4" v-if="reason==='Надо позже'">
            <Date placeholder="Когда связаться?" v-model="contactDate"/>
          </v-row>
          <v-row>
            <v-textarea
                v-model="comment"
                outlined
                rows="4"
                placeholder="Комментарий"/>
          </v-row>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader style="color: red">{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Date from "../../../components/Date";

export default {
  name: "OrderToLead",
  components: {Date},
  data() {
    return {
      loading: false,
      order_id: null,
      opened: false,
      reason: '',
      contactDate: '',
      comment: '',
      type: 0,
      error: null,
    }
  },
  methods: {
    open(order_id) {
      this.opened = true;
      this.order_id = order_id;
      this.reason = '';
      this.contactDate = '';
      this.comment = '';
      this.type = 0;
    },
    close() {
      this.opened = false;
    },
    save() {
      this.loading = true;
      this.$store.state.server.request(`order/toLead/${this.order_id}`, {
        comment: this.comment,
        reason: this.reason,
        date: this.contactDate
      }, () => {
        this.loading = false;
        this.$router.push('/orders/')
        this.close();
      }, (data) => {
        this.error = data.error
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
