<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Удалить</v-card-title>
      <v-card-text>
        <v-form
            ref="form">
          <v-row class="mb-4">
            <v-select
                v-model="formData.problem"
                :items="removeReasons"
                placeholder="Выберите причину"
                outlined
            />
          </v-row>
          <v-row>
            <v-textarea
                v-model="formData.comment"
                outlined
                rows="4"
                placeholder="Комментарий"/>
          </v-row>
          <v-row v-if="order && +order.bonus" class="mt-4">
            <v-checkbox v-model="formData.return_bonuses" :label="`Вернуть бонусы за заказ (${order.bonus} ${$store.state.user.city.country.currency})`" :true-value="1" :false-value="0"/>
          </v-row>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader style="color: red">{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="save">Удалить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Remove",
  data() {
    return {
      loading: false,
      order_id: null,
      order: null,
      opened: false,
      removeReasons: [
        {text: 'Нет клинеров'},
        {text: 'Клиенту уже не нужно'},
        {text: 'Дорого'},
        {text: 'Спам'},
        {text: 'Другое'},
      ],
      formData: {},
      error: null,
    }
  },
  methods: {
    open() {
      this.opened = true;
    },
    close() {
      this.opened = false;
    },
    onToggle() {
      this.formData = {}
    },
    save() {
      this.loading = true;
      let comment = `${this.formData.problem ? this.formData.problem + '. ' : ''}${this.formData.comment ? this.formData.comment : ''}`;
      this.$store.state.server.request(`order/remove/${this.order_id}`,
          {comment: comment, return_bonuses: this.formData.return_bonuses}, (data) => {
        this.loading = false;
        this.$router.push('/orders/')
        this.close();
      }, (data) => {
        this.error = data.error
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>