<template>
  <v-dialog persistent max-width="200px" scrollable data-app v-model="opened">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="24">mdi-close</v-icon>
      <v-card-text class="mt-3">
        <div v-for="adv in $store.state.user.advAll" v-if="adv.title">
          <v-checkbox @change="updateState(adv)"
                      :input-value="!!$store.state.user.adv.find(a=>a.n_id===adv.n_id)" :label="adv.title"/>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn block @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AdvSettings",
  data() {
    return {
      adv: [],
      opened: false,
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.adv = this.$store.state.user.adv.slice(0);
    },
    updateState(adv) {
      let index = this.adv.findIndex(a => a.n_id === adv.n_id);
      if (index === -1) {
        this.adv.push(adv);
        this.adv = this.adv.sort((a, b) => a.n_id - b.n_id);
      } else {
        this.adv.splice(index, 1);
      }
    },
    save() {
      this.$store.state.server.request('user/setParam', {
        name: 'adv',
        value: this.adv.map(a => a.n_id),
      }, () => {
        this.$store.state.user.adv = this.adv;
        this.opened = false;
      })
    }
  }
}
</script>
