<template>
  <v-dialog data-app v-model="opened" @input="onToggle()" max-width="400px">
    <v-card>
      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>
      <v-card-title>Оплата</v-card-title>
      <v-card-text>
        <v-form
            ref="form">
          <v-row class="row-d-block">
              <v-subheader>Сумма заказа</v-subheader>
              <v-text-field
                  v-model="paymentData.sum"
                  outlined
                  readonly/>
          </v-row>
          <v-row class="row-d-block" v-if="paymentType === '1'">
              <v-subheader>Карта</v-subheader>
              <v-select
                  v-model="card"
                  :items="paymentData.cards"
                  item-text="CardName"
                  item-value="CardName"
                  placeholder="Выберите карту"
                  no-data-text="Нет доступных карт"
                  return-object
                  outlined
              />
          </v-row>
          <v-row class="row-d-block" v-if="paymentData.kassa === '1' && paymentType === '2'">
              <v-subheader>Выбить чек</v-subheader>
              <v-checkbox
                  v-model="sendCheck"
                  :true-value="1"
                  :false-value="0"
                  label=""/>
          </v-row>
          <v-row v-if="error" class="error-text-modal">
            <v-subheader>{{ error }}</v-subheader>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="loading" @click="pay">Оплатить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "payment",
  data() {
    return {
      loading: false,
      order_id: null,
      paymentType: null,
      opened: false,
      error: null,
      paymentData: {},
      card: null,
      sendCheck: 1,
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.getPaymentInfo()
    },
    close() {
      this.opened = false;
    },
    onToggle() {
    },
    pay() {
      this.loading = true;
      this.$store.state.server.request(`order/pay/${this.order_id}`, {
        paymentType: this.paymentType,
        sendCheck: this.sendCheck,
        card: this.card
      }, (data) => {
        this.loading = false
        console.log(data)
        this.$eventBus.$emit('orderPayed', data.response);
        this.close()
      }, (data) => {
        this.error = data.error
        this.loading = false
      })
    },
    getPaymentInfo() {
      this.$store.state.server.request(`order/paymentInfo/${this.order_id}`, {paymentType: this.paymentType}, (data) => {
        this.paymentData = data.response;
        if (!Number(this.paymentData.kassa)) this.sendCheck = 0;
        if (this.paymentData.defaultCard) this.card = this.paymentData.cards.find(el => el.CardName === this.paymentData.defaultCard)
      })
    }
  },
  mounted() {

  },

}


</script>

<style scoped>

</style>